import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constants";

const ticketServices = createApi({
  reducerPath: "ticket",
  tagType: "ticket-booking",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.token;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),

  endpoints: (builder) => {
    return {
      getTicketsInfo: builder.query({
        query: (id) => {
          return {
            url: `/dev/users/validateBookingViaClassLink/${id}`,
            method: "GET",
          };
        },
        providesTags: ["ticket-booking"],
      }),
      getPaymentProfile: builder.query({
        query: (id) => {
          return {
            url: `/bookings/payments/getAvailablePaymentMethods?teacherID=${id}`,
            method: "GET",
          };
        },
        providesTags: ["ticket-booking"],
      }),

      getBookingConfirmation: builder.query({
        query: (id) => {
          return {
            url: `/bookings/getBookingDetails/${id}`,
            method: "GET",
          };
        },
        providesTags: ["ticket-booking"],
      }),

      getValidateWebLink: builder.query({
        query: (id) => {
          return {
            url: `/services/getServiceListForBooking/${id}`,
            method: "GET",
          };
        },
        providesTags: ["ticket-booking"],
      }),

      getValidateManageBooking: builder.query({
        query: (id) => {
          return {
            url: `/bookings/manageServiceBooking`,
            method: "POST",
            body: JSON.stringify({ manageBookingToken: id }),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
        providesTags: ["ticket-booking"],
      }),

      rescheduleBooking: builder.mutation({
        query: (data) => {
          return {
            url: `/bookings/rescheduleServiceBookings`,
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
        invalidatesTags: ["ticket-booking"],
      }),

      cancelAppointment: builder.mutation({
        query: (data) => {
          return {
            url: `/bookings/cancelServiceBooking`,
            method: "DELETE",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
      }),

      addServiceBooking: builder.mutation({
        query: (data) => {
          return {
            url: "/bookings/addServiceBookings",
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
      }),

      checkTikkiePayStatus: builder.mutation({
        query: (data) => {
          return {
            url: "/bookings/payments/checkLinkPaymentStatus",
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
      }),

      createStripePaymentIntent: builder.mutation({
        query: (data) => {
          return {
            url: "/bookings/payments/createStripePaymentIntentUA",
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
      }),

      createTikkiePaymentLink: builder.mutation({
        query: (data) => {
          return {
            url: "/bookings/payments/createPaymentLinkUA",
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
      }),
      getAvailableSlots: builder.mutation({
        query: (data) => {
          return {
            url: "/bookings/getAvailableSlots",
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
      }),
    };
  },
});

export const {
  useGetTicketsInfoQuery,
  useGetPaymentProfileQuery,
  useGetBookingConfirmationQuery,
  useGetValidateWebLinkQuery,
  useGetValidateManageBookingQuery,
  useGetAvailableSlotsMutation,
  useRescheduleBookingMutation,
  useCancelAppointmentMutation,
  useAddServiceBookingMutation,
  useCheckTikkiePayStatusMutation,
  useCreateStripePaymentIntentMutation,
  useCreateTikkiePaymentLinkMutation,
} = ticketServices;

export default ticketServices;
