import React, { useCallback } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { useDispatch } from "react-redux";
import { Button, Flex, Image, Text, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { REDUX_STATE } from "constants";
import { updatestate } from "store/reducer";
import { serviceStep } from "store/atoms";
import NEW_BOOKING_URL from "utils/getNewBookingURL";

import { TimerIcon, CheckIcon, LocationIcon } from "assets/icon";
import useStyles from "./styles";

function BookingServiceConfirmation({
  bookingConfirmationData,
  serviceData,
  where,
}) {
  const {
    paymentStatus,
    slot_date,
    slot_start_time,
    slot_end_time,
    amount,
    servicesBought,
    totalPaymentServiceFee,
  } = bookingConfirmationData;
  const id = window.localStorage.getItem("ticksID") || "";
  const email = window.localStorage.getItem("@user-email");
  let reduxstate = window.localStorage.getItem(REDUX_STATE);
  reduxstate = reduxstate ? JSON.parse(reduxstate) : {};

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, setServiceSelectedStep] = useAtom(serviceStep);
  const { classes } = useStyles();

  const isMobile = useMediaQuery("(max-width: 425px)");

  const handleRestart = useCallback(() => {
    // clear booking reservation and processing fee
    dispatch(
      updatestate({ serviceBookingReservationId: "", processingFee: 0 })
    );

    const paymentMethod = bookingConfirmationData?.paymentMethod;
    const isStripeOrTikkiePayment =
      paymentMethod === "tikkie" ||
      paymentMethod === "stripe" ||
      paymentMethod === "googlePay" ||
      paymentMethod === "applePay" ||
      paymentMethod === "ideal";

    if (isStripeOrTikkiePayment) {
      const serviceName = reduxstate.serviceData.serviceName
        .replace(/\s+/g, "-")
        .toLowerCase();

      if (reduxstate.isAllService) {
        setServiceSelectedStep(0);
      } else {
        setServiceSelectedStep(1);
      }
      navigate(`/service/${serviceName}`, { replace: true });
    } else {
      if (reduxstate.isAllService) {
        setServiceSelectedStep(0);
      } else {
        setServiceSelectedStep(1);
      }
    }
  }, [
    bookingConfirmationData?.paymentMethod,
    navigate,
    reduxstate.isAllService,
    reduxstate.serviceData.serviceName,
    setServiceSelectedStep,
  ]);

  return (
    <div className="ticket-booking order-confirmation">
      <div className="wrapper">
        <div className="page-hdr">
          <div className="title">Order Confirmation</div>
        </div>
        <div className="content">
          {/* Ticket Info*/}
          <div className="section">
            <div className="info block">
              <Flex direction="row" gap="md">
                <Image
                  src={serviceData?.servicePhoto}
                  height={rem(70)}
                  width={rem(110)}
                  radius="md"
                />
                <Flex direction="column" gap={rem(4)}>
                  <Text
                    size={isMobile ? "sm" : "md"}
                    weight={600}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {serviceData?.serviceName}
                  </Text>
                  <Flex direction="column">
                    <Text fz="sm" color="#880088">
                      {bookingConfirmationData?.servicesBought?.description}
                    </Text>
                    <Flex direction="row" gap={rem(6)}>
                      <Text fz="sm" color="dark.3">
                        {bookingConfirmationData?.servicesBought?.duration} mins
                      </Text>
                      <Text fz="sm" color="dark.3">
                        -
                      </Text>
                      <Text fz="sm" color="dark.3">
                        €{bookingConfirmationData?.servicesBought?.price}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex mt="xl" direction="column">
                <div className="item">
                  <Image
                    src={
                      bookingConfirmationData?.resourceDetail?.profilePic &&
                      bookingConfirmationData?.resourceDetail?.profilePic
                        ?.length > 0
                        ? bookingConfirmationData?.resourceDetail?.profilePic
                        : `https://avatar.iran.liara.run/username?username=${bookingConfirmationData?.resourceDetail?.fullName
                            ?.split(" ")
                            ?.join("+")}`
                    }
                    height={rem(40)}
                    width={rem(40)}
                    radius={rem(12)}
                    mr={rem(12)}
                  />
                  <div className="meta">
                    <div className="lbl">Host</div>
                    <div className="value">
                      {bookingConfirmationData?.resourceDetail?.fullName}
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <LocationIcon />
                  </div>
                  <div className="meta">
                    <div className="lbl">Where</div>
                    <div className="value">{where?.venueName}</div>
                    <div className="text">{where?.address?.address}</div>
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <TimerIcon />
                  </div>
                  <div className="meta">
                    <div className="lbl">When</div>
                    <div className="value">
                      {moment(slot_date).format("dddd, MMMM D")} from{" "}
                      {slot_start_time} - {slot_end_time}
                    </div>
                  </div>
                </div>
              </Flex>
            </div>
          </div>
          <div className="section payment-due">
            <div>
              {amount === 0 ? (
                <div className="title-free">Free Service</div>
              ) : paymentStatus === "Unpaid" ? (
                <div className="title">Payments Due</div>
              ) : (
                <div className="title">
                  Payment Confirmed
                  <div className="icon">
                    <CheckIcon />
                  </div>
                </div>
              )}
            </div>

            <div className="ticks">
              <div className="item">
                <div className="lit">
                  <div className="lbl">{serviceData?.serviceName}</div>
                  {/* <div className="price">{`€${servicesBought?.price}`}</div> */}
                  {/* <Text c="dimmed" fz="xs">{`€${totalPaymentServiceFee} processing fee`}</Text> */}
                </div>
                <div className="rit">
                  <div className="qty"></div>
                  <div className="price">
                    €
                    {Number(servicesBought?.price) +
                      (!!servicesBought?.absorbServiceFee
                        ? 0
                        : Number(servicesBought?.serviceFee))}
                  </div>
                </div>
              </div>
              <Flex justify="space-between" align="center" direction="row">
                <Text fz={rem(15)} fw="600">
                  Processing fee
                </Text>
                <Text fz={rem(15)} fw="600">
                  €{totalPaymentServiceFee}
                </Text>
              </Flex>
            </div>

            <div className="total">
              <div className="text">{`Total (incl. tax & ${`€${(
                reduxstate.processingFee || 0
              ).toFixed(2)}`} processing fee)`}</div>
              <div className="price">€{amount.toFixed(2)}</div>
            </div>
            {paymentStatus === "Unpaid" && (
              <div className="notice" style={{ color: "gray" }}>
                Host may send you a payment request before start of the event
              </div>
            )}
          </div>
          {/* <div
            className="section succcess-blk"
            style={{
              display: "grid",
              placeItems: "center",
              gap: 12,
              paddingTop: "22px",
              paddingBottom: "22px",
            }}
          > */}
          <div
            className="section success-blk"
            style={{
              display: "grid",
              placeItems: "center",
              // gap: 12,
              // paddingTop: "22px",
              // paddingBottom: "22px",
            }}
          >
            <img src="/images/success.svg" className="vector" />
            <div className="label">Your Order is Confirmed!!</div>
            <div className="text">Your Booking Details are sent to</div>
            <div className="address">{email}</div>
          </div>
          {/* </div> */}
          <div className="section block">
            <Button
              className={classes.submit}
              variant="filled"
              fullWidth
              // onClick={handleRestart}
              component="a"
              href={`${NEW_BOOKING_URL}/${id}`}
              target="_blank"
              radius={10}
            >
              Book Again
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingServiceConfirmation;
