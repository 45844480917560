import React, { useState, useEffect, useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { Flex, Button, Text } from "@mantine/core";
import { useAtom } from "jotai";

import { BOOK_SERVICE_BODY, STRIPE_AC_ID, BOOKING_ID } from "constants";
import { step as eventStep, serviceStep } from "store/atoms";

import EventInfoCard from "views/TicketBooking/EventInfoCard";
import EventBuyingSummary from "views/TicketBooking/EventBuyingSummary";
import ServiceInfoCard from "views/TicketServices/ServiceInfoCard";
import ServiceBuyingSummary from "views/TicketServices/ServiceBuyingSummary";
import { ErrorAlert, Loader, UserDetails } from "components";

import GoogleIcon from "assets/icon/Google.png";
import AppleIcon from "assets/icon/Apple.png";
import { ChevronsIconLeft } from "assets/icon";
import { useCreateStripePaymentIntentMutation } from "store/services/ticketServices";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// const stripePromise = loadStripe(
//   process.env.REACT_APP_ENVIRONMENT === "PROD"
//     ? process.env.REACT_APP_STRIPE_LIVE_KEY
//     : process.env.REACT_APP_STRIPE_TEST_KEY
//   // { stripeAccount: stripeAcId }
// );
export default function SocialPayment() {
  const location = useLocation();
  const navigate = useNavigate();

  const { type } = location.state || {};
  const reduxstate = useSelector((state) => state.State);
  const stripeAcId = window.localStorage.getItem(STRIPE_AC_ID);
  let bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY) || {};
  bookServiceBody = Object.keys(bookServiceBody).length
    ? JSON.parse(bookServiceBody)
    : {};
  const [, setEventStep] = useAtom(eventStep);
  const [, setServiceStep] = useAtom(serviceStep);

  const [stripe, setStripe] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState({});

  const [createPaymentIntent] = useCreateStripePaymentIntentMutation();

  async function getClientSecret() {
    const body = {};

    if (reduxstate.currentDataFor === "service") {
      body.serviceId = bookServiceBody.service_id;
      body.slotDuration = bookServiceBody.duration;
      body.priceType = bookServiceBody.priceType;
      body.slotPrice = Number(reduxstate.slotData?.price);
      body.slotDescription =
        reduxstate.slotData?.description || reduxstate.serviceData.serviceName;
    } else {
      body.scheduleId = reduxstate.id;

      const selectedEventPricing = reduxstate.selectedEventPricing
        .filter((item) => item.bookingQty && item.bookingQty > 0)
        .map(({ bookingQty, totalPrice, ticketType }) => ({
          bookingQty,
          totalPrice,
          ticketType,
        }));

      body.selectedEventPricing = selectedEventPricing;
    }

    const paymentMethod = type === "google" ? "googlePay" : "applePay";

    const res = await createPaymentIntent({
      amount: Number(reduxstate.totalPrice),
      paymentMethod,
      currency: "eur",
      isPackage: false,
      ...body,
    }).unwrap();

    return res?.data;
  }

  useEffect(() => {
    loadStripe(
      process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? process.env.REACT_APP_STRIPE_LIVE_KEY
        : process.env.REACT_APP_STRIPE_TEST_KEY,
      stripeAcId ? { stripeAccount: stripeAcId } : undefined
    ).then(async (stripeRes) => {
      setStripe(stripeRes);
      const { client_secret, referenceId, amountInCents } =
        await getClientSecret();
      window.localStorage.setItem(BOOKING_ID, referenceId);
      const pr = stripeRes.paymentRequest({
        country: "NL",
        currency: "eur",
        total: {
          label: "Total",
          amount: amountInCents, // in cents
        },
        requestPayerName: true,
        requestPayerEmail: false,
      });
      pr.canMakePayment().then((result) => {
        console.log(result);
        if (result) {
          setPaymentRequest(pr);
          setCanMakePayment(result);
        } else {
          const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);
          confirmAlert({
            customUI: ({ onClose }) =>
              ErrorAlert({
                onClose: () => {
                  onClose();
                  setServiceStep(4);
                  navigate(-1, { replace: true });
                },
                error: `${typeCapitalized} Pay is not supported on this device. Please select another payment method.`,
                isServerError: false,
              }),
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        }
      });
      pr.on("paymentmethod", async (ev) => {
        const { error, paymentIntent } = await stripeRes.confirmCardPayment(
          client_secret,
          {
            payment_method: ev.paymentMethod.id,
          },
          { handleActions: false }
        );
        if (error) {
          ev.complete("fail");
          confirmAlert({
            customUI: ({ onClose }) =>
              ErrorAlert({
                onClose: () => {
                  onClose();
                  setEventStep(3);
                  navigate(-1, { replace: true });
                },
                error: error.message,
                isServerError: false,
              }),
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
          return;
        }
        if (paymentIntent) {
          ev.complete("success");
          if (paymentIntent.status === "requires_action") {
            await stripeRes.confirmCardPayment(client_secret);
          }
          if (paymentIntent.status === "succeeded") {
            if (reduxstate.currentDataFor === "ticket") {
              navigate("/payment-complete", {
                state: { paymentSuccess: true },
              });
            } else if (reduxstate.currentDataFor === "service") {
              navigate("/service-payment-complete", {
                state: { paymentSuccess: true },
                replace: true,
              });
            }
          }
        }
      });
      pr.on("cancel", () => {
        confirmAlert({
          customUI: ({ onClose }) =>
            ErrorAlert({
              onClose: () => {
                onClose();
                navigate(-1, { replace: true });
              },
              error: "Payment cancelled.",
              isServerError: false,
            }),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        return;
      });
    });
  }, [stripeAcId]);

  const handleOpenSheet = () => {
    if (type === "google") {
      if (!canMakePayment.googlePay) {
        confirmAlert({
          customUI: ({ onClose }) =>
            ErrorAlert({
              onClose: () => {
                onClose();
                setServiceStep(4);
                navigate(-1, { replace: true });
              },
              error:
                "Google Pay is not supported on this device. Please add a card to your Google Pay account and try again.",
              isServerError: false,
            }),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        return;
      }
      paymentRequest.show();
    } else if (type === "apple") {
      if (!canMakePayment.applePay) {
        confirmAlert({
          customUI: ({ onClose }) =>
            ErrorAlert({
              onClose: () => {
                onClose();
                setServiceStep(4);
                navigate(-1, { replace: true });
              },
              error:
                "Apple Pay is not supported on this device. Please add a card to your Apple Pay account and try again.",
              isServerError: false,
            }),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        return;
      }
      paymentRequest.show();
    }
  };

  const handleChangePaymentMethod = () => {
    setServiceStep(4);
    navigate(-1, { replace: true });
  };

  const PayIcon = useMemo(() => {
    if (type === "google") {
      return () => (
        <img
          src={GoogleIcon}
          alt="Google Pay icon"
          style={{ width: 36, height: 36 }}
        />
      );
    }

    return () => (
      <img
        src={AppleIcon}
        alt="Apple Pay icon"
        style={{ width: 36, height: 36 }}
      />
    );
  }, [type]);

  if (!stripe || !paymentRequest || !Object.keys(canMakePayment).length) {
    return (
      <div className="ticket-view-loading">
        <Loader />
      </div>
    );
  }

  return (
    <div className="ticket-services">
      <div className="wrapper">
        <div className="page-hdr">
          <button className="back-btn" onClick={handleChangePaymentMethod}>
            <ChevronsIconLeft />
            <div className="lbl">Back</div>
          </button>
          <div className="title">Review & Pay</div>
        </div>
        <div className="content">
          <Flex direction="column">
            {reduxstate.currentDataFor === "service" ? (
              <>
                <ServiceInfoCard
                  serviceData={reduxstate.serviceData}
                  resource={reduxstate.resourceDetail}
                  isReadMore={false}
                  selectedStep={5}
                  toggleReadMore={() => {}}
                  when={{
                    day: bookServiceBody.slot_date,
                    start: bookServiceBody.slot_start_time,
                    end: bookServiceBody.slot_end_time,
                  }}
                  where={reduxstate.serviceSelectedLocation}
                  slotData={reduxstate.slotData}
                />
                <UserDetails />
                <ServiceBuyingSummary reduxstate={reduxstate} />
              </>
            ) : (
              <>
                <EventInfoCard
                  reduxstate={reduxstate}
                  selectedStep={5}
                  isReadMore={false}
                  toggleReadMore={() => {}}
                />
                <UserDetails />
                <EventBuyingSummary reduxstate={reduxstate} />
              </>
            )}
            <div className="section">
              <div className="block">
                <Flex direction="row" justify="space-between" align="center">
                  <Flex direction="row" gap="sm" align="center">
                    <PayIcon />
                    <Text fw={700} fz="lg">
                      {type.charAt(0).toUpperCase() + type.slice(1)} Pay
                    </Text>
                  </Flex>
                  <Button
                    variant="subtle"
                    onClick={handleChangePaymentMethod}
                    fz="md"
                    c="#584CF4"
                  >
                    Change
                  </Button>
                </Flex>
              </div>
            </div>
            <Button
              fullWidth
              radius={10}
              bg="#f64e60"
              size="lg"
              fz="md"
              onClick={handleOpenSheet}
              sx={{
                '&:hover': {
                  backgroundColor: "#f64e60ee",
                  transition: 'background-color 0.2s ease-in-out',
                }
              }}
            >
              Pay & Confirm booking
            </Button>
          </Flex>
        </div>
      </div>
    </div>
  );
}
